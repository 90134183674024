// import React from 'react';
import './App.css';
import Projects from './pages/projects/projects';


function App() {
  
  return (
    
    <div className="App">
      
      {/* <div id='#trailer' ref={trailer}></div> */}
      <section>
        {/* <Nav /> */}
      </section>
      <section>
        {/* <Home /> */}
        <Projects />
      </section>

    </div>
    
  );
}

export default App;
